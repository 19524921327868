<!--
* 北谷信息科技（广州）有限公司拥有本软件所有版权 © 2022，并保留所有权利
* Copyright © 2022, North Valley Information Technology (Guangzhou) Company
* Limited, All Rights Reserved.
-->
<template>
  <div class="device-inspection-log">
    <!-- 查询条件 -->
    <div class="filter-container">
      <el-card shadow="never">
        <el-form class="search-form" size="small" v-show="showSearch" ref="queryForm" :model="queryParams" :inline="true">
          <el-form-item :label="$t('deviceInspectionLog.deviceId')" prop="roleName">
            <el-input v-model="queryParams.deviceId" :placeholder="$t('common.inputPlaceholder')" clearable />
          </el-form-item>
          <el-form-item :label="$t('deviceInspectionLog.deviceName')" prop="roleName">
            <el-input v-model="queryParams.deviceName" :placeholder="$t('common.inputPlaceholder')" clearable />
          </el-form-item>
          <el-form-item :label="$t('deviceInspectionLog.adminUnitId')">
            <el-select v-model="queryParams.adminUnitId" :placeholder="$t('common.selectPlaceholder')" clearable
              filterable style="width: 100%;">
              <el-option v-for="(item, index) in adminUnitOptions" :key="index" :label="item.label" :value="item.value" />
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('deviceInspectionLog.adminSystemId')">
            <el-select v-model="queryParams.adminSystemId" :placeholder="$t('common.selectPlaceholder')" clearable
              filterable style="width: 100%;">
              <el-option v-for="(item, index) in adminSystemOptions" :key="index" :label="item.label"
                :value="item.value" />
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('deviceInspectionLog.iotModelId')">
            <el-select v-model="queryParams.iotModelId" :placeholder="$t('common.selectPlaceholder')" clearable filterable
              style="width: 100%;">
              <el-option v-for="(item, index) in iotModelOptions" :key="index" :label="item.label" :value="item.value" />
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('deviceInspectionLog.status')">
            <el-select v-model="queryParams.status" :placeholder="$t('common.selectPlaceholder')" clearable filterable
              style="width: 100%;">
              <el-option v-for="(item, index) in inspectionStatusOptions" :key="index" :label="item.label"
                :value="item.value" />
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('deviceInspectionLog.inspectedTime')">
            <el-date-picker v-model="dateRange" type="daterange" range-separator="~">
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="handleSearch">
              {{ $t('operate.search') }}</el-button>
            <el-button icon="el-icon-refresh" @click="resetSearch">{{ $t('operate.reset') }}</el-button>
          </el-form-item>
        </el-form>
      </el-card>
    </div>
    <!-- 操作符 -->
    <el-card shadow="never">
      <el-row type="flex" justify="space-between" class="mb10">
        <el-col :span="24">
          <el-button type="warning" plain icon="el-icon-download" size="mini" :loading="exportLoading"
            @click="handleExport">{{ $t('operate.export') }}</el-button>
        </el-col>
      </el-row>
      <!--  表格数据  -->
      <el-table v-loading="loading" border :data="tableData" style="width: 100% ;"
        @selection-change="handleSelectionChange">
        <!--   序号框   -->
        <el-table-column type="index" label="#" width="45" align="center">
          <template slot-scope="scope">
            {{ (queryParams.pageIndex - 1) * queryParams.pageSize + scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column prop="deviceId" :label="$t('deviceInspectionLog.deviceId')" min-width="100" align="center" />
        <el-table-column prop="deviceName" :label="$t('deviceInspectionLog.deviceName')" min-width="100" align="center" />
        <el-table-column prop="iotModelName" :label="$t('deviceInspectionLog.iotModelId')" min-width="100"
          align="center" />
        <el-table-column prop="adminUnitName" :label="$t('deviceInspectionLog.adminUnitId')" min-width="100"
          align="center" />
        <el-table-column prop="adminSystemName" :label="$t('deviceInspectionLog.adminSystemId')" min-width="100"
          align="center" />
        <el-table-column prop="inspectedTime" :label="$t('deviceInspectionLog.inspectedTime')" min-width="120"
          align="center" />
        <el-table-column prop="status" :label="$t('deviceInspectionLog.status')" min-width="100" align="center">
          <template slot-scope="scope">
            <p v-if="scope.row.status === 2" style="color: red;">{{ $t('status.anomalous') }}</p>
            <p v-else>{{ $t('status.normal') }}</p>
          </template>
        </el-table-column>
        <el-table-column prop="alarmType" :label="$t('deviceInspectionLog.alarmType')" min-width="100" align="center" />
        <el-table-column :label="$t('operate.operate')" align="center" min-width="80">
          <template slot-scope="scope">
            <el-button type="text" size="small" icon="el-icon-notebook-2" @click="handleDetail(scope.row)">
              {{ $t('operate.look') }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--分页-->
      <div class="block mt10" style="text-align:center">
        <el-pagination :current-page="listQuery.page" :page-sizes="listQuery.pageSizes" :page-size="20"
          layout="total, sizes, prev, pager, next, jumper" background :total="total" @size-change="handleSizeChange"
          @current-change="handleCurrentChange" />
      </div>
    </el-card>
    <!-- Log日志弹窗 -->
    <el-dialog :visible.sync="drawer" class="drawerDetails" :close-on-click-modal="false" :show-close="false"
      append-to-body>
      <template>
        <el-tabs type="border-card">
          <el-tab-pane :label="$t('deviceInspectionLog.title.deviceInfo')">
            <el-form :data="drawerData" label-width="120px" disabled="disabled" :label-position="labelPosition">
              <el-row>
                <el-col :xs="24" :sm="24" :lg="12">
                  <el-form-item :label="$t('deviceInspectionLog.deviceId')">
                    <el-input v-model="drawerData.deviceId" />
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="24" :lg="12">
                  <el-form-item :label="$t('deviceInspectionLog.deviceName')">
                    <el-input v-model="drawerData.deviceName" />
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="24" :lg="12">
                  <el-form-item :label="$t('deviceInspectionLog.adminUnitId')">
                    <el-input v-model="drawerData.adminUnitName" />
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="24" :lg="12">
                  <el-form-item :label="$t('deviceInspectionLog.iotModelId')">
                    <el-input v-model="drawerData.iotModelName" />
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="24" :lg="12">
                  <el-form-item :label="$t('deviceInspectionLog.adminSystemId')">
                    <el-input v-model="drawerData.adminSystemName" />
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="24" :lg="12">
                  <el-form-item :label="$t('deviceInspectionLog.registeredTime')">
                    <el-input v-model="drawerData.registeredTime" />
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </el-tab-pane>
          <el-tab-pane :label="$t('deviceInspectionLog.title.inspectedLogInfo')">
            <el-form :data="drawerData" label-width="120px" disabled="disabled" label-position="right">
              <el-row>
                <el-col :xs="24" :sm="24" :lg="12">
                  <el-form-item :label="$t('deviceInspectionLog.id')">
                    <el-input v-model="drawerData.id" :disabled="idDisabled" />
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="24" :lg="12">
                  <el-form-item :label="$t('deviceInspectionLog.inspectedTime')">
                    <el-input v-model="drawerData.inspectedTime" />
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="24" :lg="12">
                  <el-form-item :label="$t('deviceInspectionLog.deviceId')">
                    <el-input v-model="drawerData.deviceId" />
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="24" :lg="12">
                  <el-form-item :label="$t('deviceInspectionLog.alarmType')">
                    <el-input v-model="drawerData.alarmType" />
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="24" :lg="12">
                  <el-form-item :label="$t('deviceInspectionLog.status')">
                    <el-input v-if="drawerData.status === 2" :value="$t('status.anomalous')" />
                    <el-input v-else :value="$t('status.normal')" />
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="24" :lg="12">
                  <el-form-item :label="$t('deviceInspectionLog.alarmMessage')">
                    <el-input v-model="drawerData.alarmMessage" />
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </el-tab-pane>
        </el-tabs>
      </template>
      <div slot="footer" class="dialog-footer">
        <el-row type="flex" justify="space-between">
          <el-col :span="12" style="text-align: left;">
            <el-link @click="goLogByIdPage(drawerData.deviceId)" :underline="false"><i
                class="el-icon-view el-icon--right nvr-color"></i>
              历史日志信息</el-link>
          </el-col>
          <el-col :span="8" style="text-align: right;">
            <el-button type="primary" size="mini" @click="drawer = false">
              {{ $t('operate.close') }}</el-button>
          </el-col>
        </el-row>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'DeviceInspectionLog',
  data() {
    return {
      // 表格数据
      tableData: [],
      // 详情数据 -> 数据表对应所有信息
      drawerData: {},
      dateRange: [],
      // 封装分页数据
      listQuery: {
        // 当前页数
        page: 1,
        // 一页最大的记录数，默认20条
        pageSize: 20,
        // 每页显示条数的选择器
        pageSizes: [20, 50, 100, 200]
      },
      // 总记录数
      total: 0,
      // 搜索框
      input: '',
      // 新增弹框控制
      insertdialogVidible: false,
      // 遮罩层
      loading: true,
      // 查询参数
      queryParams: {
        pageIndex: 1,
        pageSize: 20,
        deviceId: undefined,
        deviceName: undefined,
        iotModelId: undefined,
        adminSystemId: undefined,
        adminUnitId: undefined,
        status: undefined,
        startTime: undefined,
        endTime: undefined
      },
      // 下拉框数据
      options: [],
      adminUnitOptions: [],
      adminSystemOptions: [],
      iotModelOptions: [],
      inspectionStatusOptions: [],
      // 显示搜索条件
      showSearch: true,
      // 导出遮罩层
      exportLoading: false,
      // 抽屉控制
      drawer: false,
      // 控制表单弹框
      open: false,
      // 表单规则
      rules: {},
      // 控制label
      labelPosition: 'right',
      // 控制ID的disabled
      idDisabled: false,
      // 记录选中行信息
      selectVal: []
    }
  },
  created() {
    // 获取请求参数，修改字段语言
    this.changeLocale()
    // 初始化数据
    this.initOption()
    this.resetQuery()
    // 获取列表数据 
    this.getList()
  },
  mounted() {

  },
  methods: {
    goLogByIdPage(deviceId) {
      const queryObj = {
        status: this.queryParams.status,
        startTime: this.queryParams.startTime,
        endTime: this.queryParams.endTime
      }
      const data = JSON.stringify(queryObj);
      this.$router.push({
        path: `/deviceInspectionLog/${deviceId}`,
        query: {
          data
        }
      })
    },
    initOption() {
      this.getAdminUnitList();
      this.getAdminSystemList();
      this.getIotModelList();
      this.inspectionStatusOptions = [{ label: this.$t('status.normal'), value: 1 }, { label: this.$t('status.anomalous'), value: 2 }]
    },
    getAdminUnitList() {
      this.$api.getOrganizationUnitList({ pageIndex: 1, pageSize: 1000 }).then(res => {
        const list = res.data?.list;
        list.forEach(unit => {
          const obj = {
            label: unit.name,
            value: unit.id
          }
          this.adminUnitOptions.push(obj);
        })
      })
    },
    getAdminSystemList() {
      this.$api.getDeviceAdminSystemList({ pageIndex: 1, pageSize: 1000 }).then(res => {
        const list = res.data?.list;
        list.forEach(unit => {
          const obj = {
            label: unit.name,
            value: unit.id
          }
          this.adminSystemOptions.push(obj);
        })
      })
    },
    getIotModelList() {
      this.$api.getIotModelList({ pageIndex: 1, pageSize: 1000 }).then(res => {
        const list = res.data?.list;
        list.forEach(unit => {
          const obj = {
            label: unit.name,
            value: unit.id
          }
          this.iotModelOptions.push(obj);
        })
      })
    },
    /**
    * @methods getList
    * @description 获取列表数据
    * @param {String} 
    */
    getList() {
      this.loading = true
      if (this.dateRange && this.dateRange.length > 0) {
        this.queryParams.startTime = this.dateRange[0]
        this.queryParams.endTime = this.dateRange[1]
      } else {
        this.queryParams.startTime = undefined
        this.queryParams.endTime = undefined
      }
      // 发送请求数据
      this.$api.getDeviceInspectionLogList(this.queryParams).then(res => {
        // 拿到结果先判断机器类型
        res.data.list.forEach(item => {
          item.inspectedTime = this.changeTime(item.inspectedTime)
          item.registeredTime = this.changeTime(item.registeredTime)
        })
        this.tableData = res.data.list
        this.total = res.data.total
      })
      // 取消遮罩层
      this.loading = false
    },
    /**
    * @methods resetQuery
    * @description 重置查询条件
    */
    resetQuery() {
      this.dateRange = []
      const now = new Date()
      this.dateRange[0] = new Date(new Date(now).getTime() - 30 * 24 * 60 * 60 * 1000)
      this.dateRange[1] = now
      this.queryParams = {
        pageIndex: 1,
        pageSize: 20,
        deviceId: undefined,
        deviceName: undefined,
        iotModelId: undefined,
        adminSystemId: undefined,
        adminUnitId: undefined,
        status: undefined,
        startTime: undefined,
        endTime: undefined
      }
    },
    /**
    * @methods resetSearch
    * @description 重置搜索条件
    */
    resetSearch() {
      this.resetQuery()
      this.getList()
    },
    /**
    * @methods changeLocale
    * @description 修改语言
    */
    changeLocale() {
      const language = this.$route.query.language
      if (language === 'en') {
        this.$i18n.locale = 'en_US'
        this.$store.commit('changeLanguage')
      } else {
        this.$i18n.locale = 'zh_CN'
      }
    },
    /**
    * @methods handleSizeChange
    * @description 点击修改每页最大数触发
    * @param {Object} params val 表示一页需要多少条数，pageSize
    */
    handleSizeChange(val) {
      // val 表示一页需要多少条数，pageSize
      this.queryParams.pageSize = `${val}`
      this.getList()
    },
    /**
    * @methods handleCurrentChange
    * @description 点击切换页数触发
    * @param {Object} params val 表示当前第几页,pageIndex
    */
    handleCurrentChange(val) {
      // val 表示当前第几页,pageIndex
      this.queryParams.pageIndex = `${val}`
      this.getList()
    },
    /**
    * @methods handleSelectionChange
    * @description 使用选框时触发
    * @param {Object} params val 表示选中的信息
    */
    handleSelectionChange(val) {
      this.selectVal = val
    },
    /**
    * @methods handleSearch
    * @description 搜索按钮
    */
    handleSearch() {
      this.queryParams.pageIndex = 1
      this.getList()
    },
    /**
    * @methods handleDetail
    * @description 查看详情按钮
    * @param {Object} params row 当前行
    */
    handleDetail(row) {
      this.drawer = true
      this.drawerData = row
    },
    /**
    * @methods handleExport
    * @description 导出按钮
    */
    handleExport() {
      this.$api.exportDeviceInspectionLogsAsExcelFile(this.queryParams).then(res => {
        this.exportFile(this.$t('export.deviceInspectionLog'), res)
      })
    },
    /**
    * @methods showTipes
    * @description 弹框提示信息
    * @param {Object} params 错误码、提示信息对应的i18n字段
    */
    showTipes(code, sucessMessage, errorMessage) {
      if (code === 1) {
        this.resetQuery()
        this.getList()
        this.$message({
          type: 'success',
          message: sucessMessage
        })
      } else {
        this.$message({
          type: 'error',
          message: errorMessage
        })
      }
    }
  }
}
</script>

<style scoped>
::v-deep .el-dialog__header {
  padding: 0.625rem 0.625rem 0.25rem;
  background-color: #f8f8f8;
  /* border-bottom: 0.0625rem solid #eee; */
  overflow: hidden;
}

::v-deep .el-dialog__body {
  padding: 0px;
}

::v-deep .el-dialog__footer {
  border-top: 1px solid #eee;
}

::v-deep .el-dialog__headerbtn {
  top: 0.9375rem;
}
</style>
